<template>
  <b-container>
    <b-input v-model="preName" placeholder="Vorname" />
    <b-input v-model="lastName" placeholder="Nachname" />
    <b-input v-model="imageUrl" placeholder="Profilbild" />
    <b-button @click="createProfile">Erstellen</b-button>
  </b-container>
</template>

<script>
export default {
  name: "CreateProfile",
  data() {
    return {
      preName: null,
      lastName: null,
      imageUrl: null,
    };
  },
  mounted() {},
  methods: {
    async createProfile() {
      const token = await localStorage.getItem("token");
      this.axios
        .post(
          "profile/create",
          {
            preName: this.preName,
            lastName: this.lastName,
            imageUrl: this.imageUrl,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          this.$router.push("/");
        });
    },
  },
  components: {},
};
</script>
